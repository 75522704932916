;(function($) {
  'use strict';
  /*global jQuery*/
  /*global window*/

  autosize(document.querySelectorAll('textarea'));

  $(function() {

    // Initialize chosen
    $('[data-chosen-select]').chosen({ width: "100%" });

    // Alerts
    var termsAccepted = Cookies.get('terms_accepted');

    if (!termsAccepted || typeof termsAccepted === 'undefined'){
      $('#termsAlert').slideDown();
    }

    $('#termsAlert').on('closed.bs.alert', function () {
      Cookies.set('terms_accepted', true);
    })

    $('[data-tooltip]').each(function() {
      var $this = $(this);
      $this.hover(function() {
        var tooltip = $this.data('tooltip');
        var $tt = $('<div />').addClass('pp-tooltip').css('display', 'none').html(tooltip);
        $tt.insertAfter($this).fadeIn('fast');
      }, function() {
        $('.pp-tooltip').remove();
      });
    });

    // Feedback form
    $('#feedback-form').each(function() {
      var $this = $(this);
      $this.submit(function() {
        var action = $this.attr('action');
        var method = $this.attr('method');

        $('#feedback-submit').prop('disabled',true);
        $('#feedback-submit').text(Lang.get('ticketsystem.submitting'));

        $('.alert', $this).remove();

        $.ajax({
          type: method,
          url: action,
          data: $this.serialize(),
          success: function(response) {
            $('#feedback-submit').prop('disabled',false);
            $('#feedback-submit').text(Lang.get('ticketsystem.submit'));

            $this[0].reset();

            if($this.attr('data-on-success') == 'close-modal') {
              $('#feedbackModal').modal('hide');
              toastr.success(Lang.get('ticketsystem.message_sent'));
            }
          },
          error: function(response) {
            var $errors = $('.error_wrapper--feedback');
            $errors.empty();
            $errors.addClass('errors');
            $.each(response.responseJSON, function( index, value ) {
              $errors.append('<li>' + value[0] + '</li>');
            });

            $('#feedback-submit').prop('disabled',false);
            $('#feedback-submit').text(Lang.get('ticketsystem.submit'));
          }
        });

        return false;
      });
    });

    // Menu
    $('.menu-toggler').click(function() {
      $(this).toggleClass('isOpen');
      $('.l-main__sidebar').toggleClass('isOpen');
    });

    // Popovers
    $('[data-toggle="popover"]').popover({ html:true });

    // Dropdowns
    $('.dropdown-selected').each(function() {
      var s_txt = $(this).text();
      var $parent = $(this).closest('.dropdown');
      $parent.find('.current-sort').html(s_txt);
    });

    // Sets a minimum height on the main content area
    // so that the footer is always at the bottom
    function body_min_height() {
      var headerHeight = $('#header').outerHeight(true),
      footerHeight = $('#footer').outerHeight(true),
      windowheight = $(window).height(),
      minHeight = 0;

      minHeight = windowheight - headerHeight - footerHeight;

      $('#maincontent').css('min-height', minHeight);
    }
    body_min_height();
    $(window).resize(body_min_height);


    function disable_remove_button($selector, items) {
      if(items <= 1) {
        $selector.addClass('disabled');
      }
      else {
        $selector.removeClass('disabled');
      }
    }

    $('[data-multiplier]').each(function() {
      var $that = $(this);
      var targetSelector = $that.data('multiplier-target');
      var addSelector = $that.data('multiplier-add');
      var removeSelector = $that.data('multiplier-remove');
      var cleanSelector = $that.data('multiplier-clean');

      $(addSelector).on('click', function() {
        var $clone = $that.find(targetSelector)
        .last()
        .clone();

        $clone
        .find(removeSelector)
        .html('')
        .val('');

        $that.find(targetSelector)
        .last()
        .after($clone);
      });

      $(removeSelector).on('click', function() {
        if($that.find(targetSelector).length > 1) {
          $that.find(targetSelector).last().remove();
        }
      });

    });

    var $wrapper = $('[data-multiinput]');
    $wrapper.each(function() {

      var $that = $(this);

      var item_selector = $that.data('multiinput-selector'),

      item_name = $that.data('multiinput-name'),
      add_selector = $that.data('multiinput-add'),
      remove_selector = $that.data('multiinput-remove'),
      number_of_inputs = $(item_selector, $that).length,
      $elem = $(item_selector, $that).first().clone();

      // When trigger is clicked we append a new input
      // To the wrapper
      $(add_selector).on('click', function(e) {
        e.preventDefault();
        var $newElem = $elem.clone();
        $newElem
        .val('')
        .attr('name', item_name + '[]')
        .appendTo($that);

        number_of_inputs++;

        disable_remove_button($(remove_selector), number_of_inputs);
      });

      // Remove last input if clicked
      $(remove_selector).on('click', function(e) {
        e.preventDefault();
        // Don't remove the last element
        if(number_of_inputs <= 1) {
          return false;
        }
        $(item_selector, $that).last().remove();
        number_of_inputs--;

        disable_remove_button($(remove_selector), number_of_inputs);
      });

      disable_remove_button($(remove_selector), number_of_inputs);

      // Ajax post and delete parent element
      $('*[data-ajax-delete-parent]').click(function(e) {
        var $that = $(this);
        e.preventDefault();
        var href = $that.attr('href');
        $.ajax({
          url: href
        }).done(function(data) {
          if(data.success) {
            $that.parent().remove();
          }
          else {
            alert('An error occured');
          }
        });
      });

    });
  });

}(jQuery));
