;(function($) {
  'use strict';
  /*global jQuery*/
  /*global window*/

    $(function() {
        $('[data-image-popup]').click(function(e) {
            e.preventDefault();

            var url = $(this).data('image-popup');
            var $popup = $('<div />').addClass('image-popup');
            var $inner = $('<div />').addClass('inner');
            var $img = $('<img />').attr('src', url);

            $inner.append($img);
            $popup.append($inner);
            $('body').append($popup);

            $popup.bind('click', function() {
                // Cleanup
                $popup.unbind('click');
                $img.remove();
                $inner.remove();
                $popup.remove();
            });

        })
    });

}(jQuery));