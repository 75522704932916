(function($) {
  'use strict';

  // Autosizes a video to the wrapper
  // Add the data-autosize-video attribute to the wrapper
  // and the data-autosize-video-target to the target video

  var scale_video = function($target, $video) {
    // Reset
    $target.height('').width('');
    $video.height('').width('');
    // Apply height
    $video
      .height($target.outerHeight(true))
      .width($target.outerWidth(true));
  };

  $(function() {
    $('[data-autosize-video]').each(function() {
      var $this   = $(this),
        $video    = $('[data-autosize-video-target]', $this),
        timer;

      timer = setTimeout(function() {
        scale_video($this, $video);
      }, 350);

      $(window).on('resize', function() {
        timer = setTimeout(function() {
          scale_video($this, $video);
        }, 350);
      });
    });
  });

})(jQuery);