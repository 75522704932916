(function($) {

  $(function() {
    $('[data-submitable-once]').each(function() {
      var $form = $(this);
      $form.one('submit', function() {
        $(this).find('input[type="submit"]')
          .attr('disabled', 'disabled')
          .css({
            'pointer-events': 'none',
            'cursor': 'default'
          });
      });
    });
  });

}(jQuery));