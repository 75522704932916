;(function($) {
    'use strict';
    /*global jQuery*/
    /*global window*/
    /*global document*/

    /* TODO */
    /*var $window = $(window),
    $stickyEl = $('#campaign-menu'),
    elTop = $stickyEl.offset().top - 115;

    $window.scroll(function() {
    $stickyEl.toggleClass('sticky', $window.scrollTop() > elTop);
});*/

    function isElementInViewport (el) {
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
    }

    function onVisibilityChange (el) {
        return function () {
            if(isElementInViewport(el)) {
                $('.btn--cta').addClass('fadeInDown');
            }
        };
    }

    // Toastr options
    $(function() {
        toastr.options = {
            "positionClass": "toast-bottom-center",
            "timeOut": "2500",
        }
    });

    $(function() {

        /** ----------------------------------------
        *  CKEDITOR SETTINGS
        *  ----------------------------------------
        * Settings for the ckeditor
        * Converts all elements with data attribute
        * wysiwyg-editor to a CKeditor
        **/
        if(typeof CKEDITOR !== "undefined") {
            $('[data-wysiwyg-editor]').each(function() {
                CKEDITOR.replace( $(this).attr('id').replace('#', ''), {
                    language: 'en',
                    // removePlugins: 'about',
                    extraPlugins: 'blockquote,format,autogrow',
                    format_tags: 'p;h2;h3',
                    toolbarGroups: [
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                        { name: 'forms', groups: [ 'forms' ] },
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                        { name: 'links', groups: [ 'links' ] },
                        { name: 'insert', groups: [ 'insert' ] },
                        { name: 'styles', groups: [ 'styles' ] },
                        { name: 'colors', groups: [ 'colors' ] },
                        { name: 'tools', groups: [ 'tools' ] },
                        { name: 'others', groups: [ 'others' ] },
                        { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons: 'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,Subscript,Superscript,Outdent,Indent,CreateDiv,JustifyBlock,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Font,FontSize,TextColor,BGColor,Maximize,ShowBlocks,About,Styles,RemoveFormat'
                });
            });
        }

        if(($('.btn--cta').length)) {
            var handler = onVisibilityChange($('.btn--cta')[0]);
            $(window).on('DOMContentLoaded load resize scroll', handler);
        }

        $('[data-percentage]').each(function() {
            var percentage = $(this).data('percentage');
            $(this).css({'width' : 0, 'opacity' : 0});
            $(this).delay(500).animate({
                width : percentage + '%',
                opacity : 1
            }, 1200);
        });

        $('.hidden').hide();
        $('[data-show]').css('cursor', 'pointer');
        $('[data-show]').click(function(e) {
            e.preventDefault();
            var elemSel = $(this).data('show');

            $('[data-hide]').fadeOut('fast', function() {
                $(elemSel).fadeIn();
            });

        });

        // Toggle
        $('[data-toggler]').each(function() {
            var elemSel;
            $(this).css('cursor', 'pointer');
            if(!$(this).attr('[data-toggler-show]')) {
                elemSel = $(this).data('toggler');
                $(elemSel).hide();
            }
        });
        $('[data-toggler]').click(function(e) {
            e.preventDefault();
            var elemSel = $(this).data('toggler');
            $(elemSel).slideToggle('fast');
        });

        // Check
        $('[data-toggle-element]').each(function() {
            var $this = $(this);
            var $el = $($this.data('toggle-element'));
            var setElementVisibility = function() {
                if ($this.is(':checked'))
                    $el.show();
                else
                    $el.hide();
            };
            
            setElementVisibility();
            $this.on('change', setElementVisibility);
        });

        // Show change password area in profile edit
        var check_new_password_area = function () {
            if($('INPUT#changepassword').is(':checked'))
            $('.newpassword-input').show();
            else
            $('.newpassword-input').hide();
        };
        $('INPUT#changepassword').on('change', check_new_password_area);
        check_new_password_area();

        // Show money and resource forms when checkbox is checked
        var check_money_area = function () {
            if($('INPUT#has_money_goal').is(':checked'))
            $('.money-input').removeClass("hide");
            else
            $('.money-input').addClass("hide");
        };
        $('INPUT#has_money_goal').on('change', check_money_area);
        check_money_area();

        var check_resource_area = function() {
            if($('INPUT#has_resources_goal').is(':checked'))
            $('.resources-input').removeClass("hide");
            else
            $('.resources-input').addClass("hide");
        };
        $('INPUT#has_resources_goal').on('click', check_resource_area);
        check_resource_area();

        var check_grouppledge_area = function() {
            if($('INPUT#has_grouppledge_goal').is(':checked'))
            $('.grouppledge-input').removeClass("hide");
            else
            $('.grouppledge-input').addClass("hide");
        };
        $('INPUT#has_grouppledge_goal').on('click', check_grouppledge_area);
        check_grouppledge_area();

        var check_vote_area = function() {
            if($('INPUT#has_vote_enabled').is(':checked'))
            $('.vote-input').removeClass("hide");
            else
            $('.vote-input').addClass("hide");
        };
        $('INPUT#has_vote_enabled').on('click', check_vote_area);
        check_vote_area();

    });


    /** ----------------------------------------
    *  Modals
    *  ----------------------------------------
    **/
    $('table[data-form="adminForm"]').on('click', '.form-delete', function(e){
        e.preventDefault();
        var $form=$(this);
        $('#confirm').modal({ backdrop: 'static', keyboard: false })
        .on('click', '#delete-btn', function(){
            $form.submit();
        });
    });


    /** ----------------------------------------
    *  Notifications
    *  ----------------------------------------
    **/

    // Mark all notifications as read
    $( "#mark-all-read" ).on( "click", function(e) {
        e.preventDefault();
        e.stopPropagation();

        $.ajax({
            url: "/notify/markallasread",
            type:"GET",
            success:function(){
                removeNotificationElements();
            },error:function(){
                toastr.warning(Lang.get('messages.error'));
            }
        });

    });

    // Mark a specific notification as read
    $( ".mark-as-read" ).on( "click", function(e) {
        e.preventDefault();
        e.stopPropagation();

        var button = $(this);
        var notification_id = button.data('id');
        var notification_count = $('.usermenu__notifications-count').text();

        $.ajax({
            url: "/notify/" + notification_id + "/markasread",
            type:"GET",
            success:function(){
                button.closest('.notifications__item').remove();

                notification_count -= 1;
                $('.usermenu__notifications-count').text(notification_count);

                if( notification_count == 0 ){
                    removeNotificationElements();
                }
            },error:function(){
                toastr.warning(Lang.get('messages.error'));
            }
        });
    });

    // Mark a specific notification as read before redirecting to the notification URL
    $( ".notification-link" ).on( "click", function(e) {
        e.preventDefault();
        e.stopPropagation();

        var href = this.href;
        var notification = $(this);
        var notification_id = notification.data('id');

        $.ajax({
            url: "/notify/" + notification_id + "/markasread",
            type:"GET",
            success:function(){
                window.location = href;
            },error:function(){
                toastr.warning(Lang.get('messages.error'));
            }
        });
    });

    // Remove counter and notifications list when all notifications are marked as read
    function removeNotificationElements () {
        $('.usermenu__notifications-count').remove();
        $('.notifications__item-list').remove();
        $('#mark-all-read').remove();
        $('.notifications__header-links').html(Lang.get('messages.notifications_no_new'));
        $("#notification-dropdown").dropdown("toggle");
    }


    /** ----------------------------------------
    *  Tooltips
    *  ----------------------------------------
    **/

    $('[data-toggle="tooltip"]').tooltip();

}(jQuery));
